import * as utils from '../utils'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import Court from '../Components/Court'
import DecisionMode from '../Components/DecisionMode'
import DrillPlayerSlots from '../Components/DrillPlayerSlots'
import EditDialog from './EditDialog'
import FileUpload from '../Components/FileUpload'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import React, { useRef, useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Stack from '../Components/Stack'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import catalogHelpers from '../catalogHelpers'
import drillHelpers from '../drillHelpers'
import { FileKinds } from '../types'
import { getEditSessionGetterAndSetter, startEditSession } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useStore } from '../StoreProvider'

const courtLib = window.courtLib
const { DrillLimitGoal, DrillLimitKind, LocationBallActionKind, LocationBallShotMode, LocationBallShotType, LocationKind } = courtLib.types

const isMobileMediaQuery = '@media (max-width: 1133px)'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	ballOrder: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
	},
	container: {
		borderLeft: '2px solid white',
		height: 600,
		overflowY: 'auto',
		padding: theme.spacing(2),
		width: 360,
		flexShrink: 0,
	},
	video: {
		marginBottom: theme.spacing(2)
	},
	[isMobileMediaQuery]: {
		root: {
			flexDirection: 'column',
			overflowX: 'hidden',
			overflowY: 'auto',
			height: '100%',
		},
		container: {
			borderLeft: 'none',
			height: 'auto',
			width: 'auto',
			margin: 0,
		},
	},
	'@media (min-width: 784px)': {
		court: {
			background: 'black',
			display: 'flex',
			justifyContent: 'center',
		},
	},
}))

function getLocationBallShotModeOptions(data) {
	const options = [
		[LocationBallShotMode.shots_per_spot, 'SHOTS'],
		[LocationBallShotMode.must_be_made_per_spot, 'MUST BE MADE'],
		[LocationBallShotMode.makes_in_a_row_per_spot, 'MAKES IN A ROW'],
	]

	if (isSwishesDrill(data)) {
		options.push([LocationBallShotMode.must_be_swishes_per_spot, 'MUST BE SWISHES'])
		options.push([LocationBallShotMode.swishes_in_a_row_per_spot, 'SWISHES IN A ROW'])
	}

	return options.map(([value, name]) => <option key={value} value={value}>{name}</option>)
}

function isProcessingZencoderJob(zencoderJob) {
	if (!zencoderJob) return false
	switch (zencoderJob.job_state) {
		case 'assigning': return true
		case 'cancelled': return false
		case 'failed': return false
		case 'finished': return false
		case 'no input': return false
		case 'processing': return true
		case 'queued': return true
		case 'skipped': return false
		case 'waiting': return true
		default: return true
	}
}

function isSwishesDrill(data) {
	if (!data) return false

	if (data.mode === LocationBallShotMode.must_be_swishes_per_spot) return true
	if (data.mode === LocationBallShotMode.swishes_in_a_row_per_spot) return true

	if (!data.locations) return false
	for (const { ballShot } of data.locations) {
		if (!ballShot || !ballShot.is_custom) continue
		if (ballShot.mode === LocationBallShotMode.must_be_swishes_per_spot) return true
		if (ballShot.mode === LocationBallShotMode.swishes_in_a_row_per_spot) return true
	}

	return false
}

function AdvancedSection({ courtRef, selection, setSelection, editSession }) {
	const classes = useStyles()
	const isMobile = useMediaQuery(isMobileMediaQuery)

	const { data: { data } } = editSession

	const handleSelectedLocationDone = () => {
		courtRef.current.locations.select()
		setSelection(null)
	}

	const handleShiftSelectedLocation = (backwards) => () => {
		courtRef.current.locations.shift(backwards)
	}

	const setSelectedLocationField = (fieldName, eventFieldName = 'value') => (e) => {
		let value = parseInt(e.target[eventFieldName])
		if (isNaN(value)) value = e.target[eventFieldName]

		let obj = selection.location
		let keys = fieldName.split('.')
		fieldName = keys.pop()
		for (const key of keys) {
			obj = obj[key]
		}
		obj[fieldName] = value

		if (fieldName === 'is_custom') {
			let { mode, per_spot, seconds_between_throws } = data
			const { location } = selection
			if (location.ballShot) {
				location.ballShot.mode = mode
				location.ballShot.per_spot = per_spot
				location.ballShot.seconds_between_throws = seconds_between_throws
				courtRef.current.locations.draw(location)
			}
		}
		else if (fieldName === 'player_index') {
			const { location } = selection
			courtRef.current.locations.draw(location, { playerIndex: value })
		}

		setSelection({ ...selection })
	}

	const doneButton =
		<Button variant='contained' style={{ width: '100%' }} onClick={handleSelectedLocationDone}>DONE</Button>

	const { is_custom, mode, per_spot, player_index, seconds_between_throws, shot_type } = selection.location.ballShot

	return <Stack key={'advanced-' + (is_custom ? '1' : '0')}>
		{ isMobile ? doneButton : undefined }

		<FormControlLabel label='MAKE CUSTOM' control={<Checkbox
			checked={is_custom}
			onChange={setSelectedLocationField('ballShot.is_custom', 'checked')}
		/>}/>

		<Stack horizontal>
			<FormControl disabled={!is_custom}>
				<Select native style={{ width: '100%' }}
					value={is_custom ? mode : data.mode}
					onChange={setSelectedLocationField('ballShot.mode')}>
                    {getLocationBallShotModeOptions(data)}
				</Select>
				<FormHelperText>PER SPOT</FormHelperText>
			</FormControl>
			<FormControl disabled={!is_custom}>
				<Select native style={{ width: '100%' }}
					value={is_custom ? per_spot : data.per_spot}
					onChange={setSelectedLocationField('ballShot.per_spot')}>
					{new Array(999).fill(1).map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
				</Select>
				<FormHelperText>SHOTS PER SPOT</FormHelperText>
			</FormControl>
		</Stack>

		<Stack horizontal>
			<FormControl disabled={!is_custom}>
				<Select native style={{ width: '100%' }}
					value={is_custom ? seconds_between_throws : data.seconds_between_throws}
					onChange={setSelectedLocationField('ballShot.seconds_between_throws')}>
					{new Array(99).fill(1).map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
				</Select>
				<FormHelperText>SECONDS BETWEEN THROWS</FormHelperText>
			</FormControl>
			<FormControl>
				<Select native style={{ width: '100%' }}
					value={shot_type}
					onChange={setSelectedLocationField('ballShot.shot_type')}>
					<option value={LocationBallShotType.catch_and_shoot}>CATCH & SHOOT</option>
					<option value={LocationBallShotType.off_the_dribble}>OFF THE DRIBBLE</option>
					<option value={LocationBallShotType.on_the_move}>ON THE MOVE</option>
				</Select>
				<FormHelperText>SHOT TYPE</FormHelperText>
			</FormControl>
		</Stack>

		{data.player_count > 1 && <FormControl>
			<Select native style={{ width: '100%' }}
				value={player_index}
				onChange={setSelectedLocationField('ballShot.player_index')}>
				{new Array(data.player_count).fill(1).map((_, i) => <option key={i} value={i}>{i + 1}</option>)}
			</Select>
			<FormHelperText>PLAYER</FormHelperText>
		</FormControl>}

		<Stack spacing={false}>
			<div style={{ display: 'flex' }}>
				<IconButton edge='start' color='inherit' onClick={handleShiftSelectedLocation(true)}><ArrowBackIcon /></IconButton>
				<Typography variant='h5' className={classes.ballOrder}>{selection.number}</Typography>
				<IconButton edge='end' color='inherit' onClick={handleShiftSelectedLocation(false)}><ArrowForwardIcon /></IconButton>
			</div>
			<FormHelperText style={{ textAlign: 'center', marginTop: 0 }}>ORDER</FormHelperText>
		</Stack>

		{ !isMobile ? doneButton : undefined }
	</Stack>
}

function GlobalSection({ courtRef, hasLocations, editSession, setEditSession, setHasLocations }) {
	const isMobile = useMediaQuery(isMobileMediaQuery)

	const { state, dispatch } = useStore()
	const [ forceLimitRender, setForceLimitRender ] = useState(0)
	const [ , setDrill ] = getEditSessionGetterAndSetter(editSession, setEditSession)
	const { data: { data, description, name, zencoder_job } } = editSession
	const [ globalBallActionLocation, setGlobalBallActionLocation ] = useState(null)

	useEffect(() => {
		let location = data.locations.find(x => x.kind === LocationKind.globalBallAction)
		if (!location) {
			location = courtLib.types.newLocationGlobalBallAction({ angle: 90, distance: 200 })
		}
		setGlobalBallActionLocation(location)
		// eslint-disable-next-line
	}, [])

	const shouldChangeMaxPlayerCount = async (newPlayerCount) => {
		const { locations } = data

		const maxPlayerIndex = locations.reduce((value, { ballShot }) => {
			if (!ballShot) return value
			return Math.max(value, ballShot.player_index)
		}, 0)

		const targetMaxPlayerIndex = newPlayerCount - 1

		if (targetMaxPlayerIndex >= maxPlayerIndex) {
			return true
		}

		const locationsToReset = locations.filter(({ ballShot }) => {
			return ballShot && ballShot.player_index > targetMaxPlayerIndex
		})

		if (locationsToReset.length === 0) {
			return true
		}

		const message = `Lowering the player count will reset ${locationsToReset.length} location${locationsToReset.length === 1 ? "" : "s"} to player 1.`
		if (!await state.confirm(dispatch, 'NOTICE', message, 'Continue', 'Cancel')) {
			return false
		}

		for (const location of locationsToReset) {
			location.ballShot.player_index = 0
			courtRef.current.locations.draw(location, { playerIndex: 0 })
		}

		return true
	}

	const handleChangeGlobalBallAction = ({ target: { value } }) => {
		const kind = parseInt(value)
		const hasLocation = courtRef.current.locations.contains(globalBallActionLocation)

		if (!kind) {
			if (hasLocation) {
				courtRef.current.locations.remove(globalBallActionLocation)
			}
			return
		}

		if (!hasLocation) {
			courtRef.current.locations.add(globalBallActionLocation, 0)
		}

		courtRef.current.locations.convert({ ballAction: { kind } }, globalBallActionLocation)
	}

	const handleChangeLimitCount = (e) => {
		let limitCount = parseInt(e.target.value)

		const maxCount = data.limit_kind === DrillLimitKind.time ? (60 * 60) : 999
		data.limit_count = Math.max(1, Math.min(maxCount, limitCount || 1))

		if (data.limit_count !== limitCount) {
			// allows them to clear the field, so they can start typing a new number
			if (!isNaN(limitCount)) setForceLimitRender(forceLimitRender + 1)
			editSession.changed = true
			setEditSession(editSession.clone())
		}
	}

	const handleChangeLimitKind = (e) => {
		data.limit_kind = parseInt(e.target.value)
		data.limit_goal = DrillLimitGoal.none
		data.limit_count = data.limit_kind === DrillLimitKind.time ? 60 : 1

		editSession.changed = true
		setEditSession(editSession.clone())
		setForceLimitRender(forceLimitRender + 1)
	}

	const handleClearLocations = async () => {
		if (await state.confirm(dispatch, 'CONFIRM', 'Clear all locations?', 'YES', 'NO')) {
			setHasLocations(false)
			courtRef.current.locations.clear()
		}
	}

	const getSelectedGlobalBallAction = () => {
		const location = data.locations.find(x => x.kind === LocationKind.globalBallAction)
		if (location) {
			return location.ballAction.kind.toString()
		}
	}

	const handleChangePlayerCount = async (e) => {
		let value = parseInt(e.target.value)

		if (!await shouldChangeMaxPlayerCount(value)) {
			value = data.player_count
		}

		data.player_count = value
		setEditSession(editSession.clone())
	}

	const handleVideoChange = (file) => {
		editSession.changed = true
		editSession.data.videoFile = file
	}

	const handleVideoInvalidTypeProvided = () => {
		return state.showErrorAsync(dispatch, 'Only video files can be uploaded.')
	}

	const handleVideoRemove = async (e) => {
		if (await state.confirm(dispatch, 'CONFIRM', "Are you sure you'd like to clear the previously uploaded video?", 'YES', 'NO')) {
			editSession.changed = true
			editSession.data.video_file_id = null
			editSession.data.zencoder_job = null
			setEditSession(editSession.clone())
		}
	}

	const handleVideoView = (file) => {
		if (file) {
			state.showVideo(dispatch, URL.createObjectURL(file))
		}
		else {
			state.showVideo(dispatch, '/files/' + editSession.data.video_file_id)
		}
	}

	const handleVideoViewError = (e) => {
		return state.showAlert(dispatch, {
            title: 'Transcode Error',
            message: editSession.data.zencoder_job.error_message,
        })
	}

	const handleVideoViewInfo = (e) => {
		return state.showAlert(dispatch, {
            title: 'INFO',
            message: 'The uploaded video is currently being processed.',
        })
	}

	const getLimitGoalOptionsForLimitKind = (limitKind) => {
		const options = [
			<option key='none' value={DrillLimitGoal.none}>NONE</option>
		]

		switch (limitKind) {
			case DrillLimitKind.makes:
				options.push(<option key='time' value={DrillLimitGoal.time}>QUICKEST TIME</option>)
				options.push(<option key='shots' value={DrillLimitGoal.shots}>LEAST SHOTS</option>)
				break
			case DrillLimitKind.shots:
				options.push(<option key='makes' value={DrillLimitGoal.makes}>MOST MAKES</option>)
				// options.push(<option key='time' value={DrillLimitGoal.time}>QUICKEST TIME</option>)
				break
			case DrillLimitKind.time:
				options.push(<option key='makes' value={DrillLimitGoal.makes}>MOST MAKES</option>)
				// options.push(<option key='shots' value={DrillLimitGoal.shots}>MOST SHOTS</option>)
				break
			case DrillLimitKind.number_of_runs:
				options.push(<option key='makes' value={DrillLimitGoal.makes}>MOST MAKES</option>)
				options.push(<option key='time' value={DrillLimitGoal.time}>QUICKEST TIME</option>)
				options.push(<option key='shots' value={DrillLimitGoal.shots}>LEAST SHOTS</option>)
				break
			default:
		}

		return options
	}

	const clearLocationsButton = !hasLocations ? undefined :
		 <Button variant='contained' color='primary' style={{ bottom: 0, position: 'sticky', width: '100%' }} onClick={handleClearLocations}>CLEAR LOCATIONS</Button>

	return <Stack key='global'>
		{ isMobile ? clearLocationsButton : undefined }

		<TextField label='NAME' fullWidth defaultValue={name} onChange={setDrill('name')} autoFocus={!isMobile} />
		<TextField label='DESCRIPTION' multiline fullWidth defaultValue={description} onChange={setDrill('description')} />

		<Stack horizontal={isMobile}>
			<FormControl>
				<Select native style={{ width: '100%' }}
					defaultValue={data.mode.toString()}
					onChange={setDrill('data.mode', { onTransform: parseInt })}>
				  	{getLocationBallShotModeOptions(data)}
				</Select>
				<FormHelperText>PER SPOT</FormHelperText>
			</FormControl>
			<FormControl>
				<Select native style={{ width: '100%' }}
					defaultValue={data.per_spot}
					onChange={setDrill('data.per_spot', { onTransform: parseInt })}>
					{new Array(999).fill(1).map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
				</Select>
				<FormHelperText>SHOTS PER SPOT</FormHelperText>
			</FormControl>
		</Stack>

		<Stack horizontal={isMobile}>
			<FormControl>
				<Select native style={{ width: '100%' }}
					defaultValue={data.seconds_between_throws}
					onChange={setDrill('data.seconds_between_throws', { onTransform: parseInt })}>
					{new Array(99).fill(1).map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
				</Select>
				<FormHelperText>SECONDS BETWEEN THROWS</FormHelperText>
			</FormControl>
		</Stack>

		<Stack small>
			<FormControl>
				<Select native style={{ width: '100%' }}
					value={data.player_count}
					onChange={handleChangePlayerCount}>
					{new Array(8).fill(1).map((_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
				</Select>
				<FormHelperText>PLAYER COUNT</FormHelperText>
			</FormControl>
			<DrillPlayerSlots count={data.player_count} />
		</Stack>

		<Stack horizontal={true} grow={false} small={true}>
			<FormControlLabel label='SEQUENCE SHOOTING' control={<Checkbox
				checked={data.is_sequential}
				onChange={setDrill('data.is_sequential', { isCheckbox: true })}
			/>}/>
			<FormControlLabel label='GROUP DRILL' control={<Checkbox
				checked={editSession.data.is_group_drill}
				onChange={setDrill('is_group_drill', { isCheckbox: true })}
			/>}/>
		</Stack>

		{<Stack grow={false} small={true}>
			<FormControlLabel label='DECISION MODE' control={<Checkbox
				checked={data.is_decision_mode}
				onChange={setDrill('data.is_decision_mode', { isCheckbox: true })}
			/>}/>
            {data.is_decision_mode && <DecisionMode
                decisions={data.decisions}
                onChange={(key, value) => {
                    editSession.changed = true
                    editSession.data.data[key] = value
                    setEditSession(editSession.clone())
                }}
                showColor={data.decision_mode_show_color}
                showIcon={data.decision_mode_show_icon}
                showText={data.decision_mode_show_text}
            />}
		</Stack>}

		<Typography variant="h6">Advanced</Typography>
		<FormControl key={'global-ball-action-' + getSelectedGlobalBallAction()}>
			<Select native style={{ width: '100%' }}
				defaultValue={getSelectedGlobalBallAction()}
				onChange={handleChangeGlobalBallAction}>
				<option value={undefined}>NONE</option>
				<option value={LocationBallActionKind.dribble}>DRIBBLE</option>
				<option value={LocationBallActionKind.pass}>PASS</option>
			</Select>
			<FormHelperText>MOVE</FormHelperText>
		</FormControl>

		<FormControl key={'limit-kind-' + data.limit_kind}>
			<Select native style={{ width: '100%' }}
				defaultValue={data.limit_kind.toString()}
				onChange={handleChangeLimitKind}>
				<option value={DrillLimitKind.none}>NONE</option>
				<option value={DrillLimitKind.makes}>MAKES</option>
				<option value={DrillLimitKind.shots}>SHOTS</option>
				<option value={DrillLimitKind.time}>TIME</option>
				<option value={DrillLimitKind.number_of_runs}>NUMBER OF RUNS</option>
			</Select>
			<FormHelperText>CHALLENGE</FormHelperText>
		</FormControl>

		<Stack horizontal={isMobile}>
			<FormControl key={'count-' + (data.limit_count ?? 1) + '-' + forceLimitRender}>
				<TextField
					defaultValue={data.limit_count}
					onChange={handleChangeLimitCount}
					InputProps={{
						inputProps: {
							min: 1,
							max: data.limit_kind === DrillLimitKind.time ? (60 * 60) : 999,
							type: 'number',
							onBlur: (e) => {
								if (e.target.value) return
								handleChangeLimitCount({ target: { value: 1 } })
								setForceLimitRender(forceLimitRender + 1)
							},
						},
					}}
				/>
			<FormHelperText>{data.limit_kind === DrillLimitKind.time ? 'COUNT (seconds)' : 'COUNT'}</FormHelperText>
			</FormControl>
			<FormControl key={'goal-' + (data.limit_goal ?? 1) + '-' + forceLimitRender}>
				<Select native style={{ width: '100%' }}
					defaultValue={data.limit_goal.toString()}
					onChange={setDrill('data.limit_goal', { onTransform: parseInt })}>
					{getLimitGoalOptionsForLimitKind(data.limit_kind)}
				</Select>
				<FormHelperText>GOAL</FormHelperText>
			</FormControl>
		</Stack>

		<FileUpload what='Video'
			acceptTypes={['video/*']}
			hasError={Boolean(zencoder_job?.error_message)}
			hasExistingFile={Boolean(editSession.data.video_file_id)}
			hasInfo={isProcessingZencoderJob(zencoder_job)}
			onChange={handleVideoChange}
			onInvalidTypeProvided={handleVideoInvalidTypeProvided}
			onRemove={handleVideoRemove}
			onView={handleVideoView}
			onViewError={handleVideoViewError}
			onViewInfo={handleVideoViewInfo}
		/>

		{ !isMobile ? clearLocationsButton : undefined }
	</Stack>
}

export default function DrillEditDialog2() {
	const classes = useStyles()
	const isMobile = useMediaQuery(isMobileMediaQuery)
	const courtRef = useRef()

	const { state, dispatch } = useStore()
	const { open, clone, drill, drillId } = state.drillEditDialog

	const [ editSession, setEditSession ] = useState(null)
	const [ selection, setSelection ] = useState(null)
	const [ hasLocations, setHasLocations ] = useState(false)

	useEffect(() => {
		if (!editSession?.data) return
		setHasLocations(editSession.data.data.locations.length > 0)
        const { is_decision_mode, decisions } = editSession.data.data
        if (is_decision_mode && decisions.length === 0) {
            for (const decision of courtLib.types.getDefaultDecisions()) {
                decisions.push(decision)
            }
			editSession.changed = true
			editSession.data.data.decisions = decisions
			setEditSession(editSession.clone())
        }
	}, [editSession])

	const uploadVideo = async (drillId, videoFile) => {
		if (!videoFile) return true

		state.showLoading(dispatch, 'Uploading video...')

		try {
			state.setLoadingProgress(dispatch, 0)
			const res = await api.uploadFile(videoFile, FileKinds.drill_video_upload, (percentage) => {
				state.setLoadingProgress(dispatch, percentage)
			})
			state.setLoadingProgress(dispatch, 100)

			if (!res.ok) {
				state.hideLoading(dispatch)
				await state.showErrorAsync(dispatch, res.extractRemainingErrorsMessage())
				return
			}

			const res2 = await api.postZencoderTranscode({
				entity_id: drillId,
				entity_kind: 'drill',
				file_id: res.id,
			})
			if (!res2.ok) {
				state.hideLoading(dispatch)
				await state.showErrorAsync(dispatch, res2.extractRemainingErrorsMessage())
				return false
			}
		}
		catch (e) {
			state.hideLoading(dispatch)
			await state.showErrorAsync(dispatch, e.message)
			return false
		}

		state.hideLoading(dispatch)

		return true
	}

	useEffect(() => {
		if (!open) return

		return utils.withCancel(async (cancelled) => {
			if (!drill) {
				state.showLoading(dispatch)
				const res = await api.getDrill(drillId)
				state.hideLoading(dispatch)

				if (!res.ok) {
					hide()
					state.showError(dispatch, res.extractRemainingErrorsMessage())
					return
				}

				if (!cancelled()) {
					dispatch({ type: 'drill_edit_dialog.loaded', drill: res.drill })
				}
				return
			}

			drillHelpers.upgrade(drill)

			const isNew = !drill.id

			const drillOwnedByCurrentUser = !clone && (isNew || catalogHelpers.entityOwnedByCurrentUser(state.user, drill))

			if (!drillOwnedByCurrentUser)
				delete drill.id

			await startEditSession(setEditSession, drill, {
				title: (drillOwnedByCurrentUser ? (isNew ? 'Create' : 'Edit') : (clone ? 'Clone' : 'Customize')) + ' Drill',
				async onCancel() {
					const what = isNew ? 'new' : (clone ? 'cloned' : 'changes to this')
					const msg = `Are you sure you'd like to discard your ${what} drill?`
					return state.confirm(dispatch, 'CONFIRM', msg)
				},
				async onComplete(item) {
					if (drillHelpers.getNormalLocationCount(item.data.locations) === 0) {
						await state.showErrorAsync(dispatch, 'This drill must have at least one location to be saved.')
						return false
					}

					state.showLoading(dispatch, (drillOwnedByCurrentUser ? (isNew ? 'Creating' : 'Updating') : (clone ? 'Cloning' : 'Saving')) + '  Drill...')

					let res
					if (drillOwnedByCurrentUser) {
						if (isNew) res = await api.postDrill(item)
						else res = await api.patchDrill(item.id, item)
					}
					else {
						item.category_id = state.user.root_category_id
						res = await api.postDrill(item)
					}

					if (!res.ok) {
						state.hideLoading(dispatch)
						await state.showErrorAsync(dispatch, res.extractRemainingErrorsMessage())
						return false
					}

					if (!item.id) item.id = res.drill_id

					const videoFile = item.videoFile
					item.videoFile = null
					if (!await uploadVideo(item.id, videoFile)) {
						state.hideLoading(dispatch)
						return false
					}

					state.setDrillsDirty(dispatch, true)
					state.hideLoading(dispatch)
					return true
				},
			})
		})
		// eslint-disable-next-line
	}, [state.drillEditDialog])


	if (!editSession) {
		return <></>
	}

	const { data: { data } } = editSession

	const hide = () => {
		dispatch({ type: 'drill_edit_dialog.hide' })
	}

	const handleExited = async () => {
		setSelection(null)
		editSession.data.videoFile = null
		hide()
	}

	const handleLocationAdded = (location) => {
		const { ballShot } = location

		if (!ballShot) return

		const { locations, player_count } = data

		let playerIndex = 0
		const selectedLocation = courtRef.current.locations.selected()
		if (selectedLocation && selectedLocation.ballShot) {
			playerIndex = selectedLocation.ballShot.player_index
		}
		else {
			const lastLocation = locations.findLast((otherLocation) => {
				if (otherLocation === location) return false
				return Boolean(otherLocation.ballShot)
			})
			if (lastLocation) {
				playerIndex = lastLocation.ballShot.player_index + 1
			}
			if (playerIndex > player_count - 1) {
				playerIndex = 0
			}
		}
		if (ballShot.player_index !== playerIndex) {
			ballShot.player_index = playerIndex
			courtRef.current.locations.draw(location, { playerIndex })
		}
	}

	const handleLocationsChanged = () => {
		editSession.changed = true
		setSelection((selection) => {
			if (!selection) return null
			const { location } = selection
			return { location, number: courtRef.current.locations.getVisualIndex(location) }
		})
	}

	const handleLocationCountChanged = () => {
		setHasLocations(data.locations.length > 0)
	}

	const handleLocationSelected = (location) => {
		if (!location) {
			setSelection(null)
			return
		}
		setSelection({ location, number: courtRef.current.locations.getVisualIndex(location) })
	}

	return <EditDialog entityName='Drill' editSession={editSession} contain={false} isMobile={isMobile} onExited={handleExited}>
		<div className={classes.root}>
			<Court passedInRef={courtRef} data={data} interactive={true} responsive className={classes.court}
				onLocationAdded={handleLocationAdded}
				onLocationsChanged={handleLocationsChanged}
				onLocationCountChanged={handleLocationCountChanged}
				onLocationSelected={handleLocationSelected}
			/>
			<Container className={classes.container}>{selection
				? <AdvancedSection courtRef={courtRef} selection={selection} setSelection={setSelection} editSession={editSession} />
				: <GlobalSection courtRef={courtRef} hasLocations={hasLocations} editSession={editSession} setEditSession={setEditSession} setHasLocations={setHasLocations} />
			}</Container>
		</div>
	</EditDialog>
}
