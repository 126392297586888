import * as utils from '../utils'
import AssignedDrillsList from '../Components/AssignedDrillsList'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Court from '../Components/Court'
import DecisionMode from '../Components/DecisionMode'
import DrillPlayerSlots from '../Components/DrillPlayerSlots'
import FeaturedDrillsList from '../Components/FeaturedDrillsList'
import React, { useEffect, useRef, useState } from 'react'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import catalogHelpers from '../catalogHelpers'
import drillHelpers from '../drillHelpers'
import { DrillLimitGoal, DrillLimitKind } from '../types'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useStore } from '../StoreProvider'

const isMobileMediaQuery = '@media (max-width: 1133px)'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
	},
	container: {
		height: 600,
		overflowY: 'auto',
		padding: theme.spacing(2),
		width: 360,
		flexShrink: 0,
	},
	[isMobileMediaQuery]: {
		root: {
			flexDirection: 'column',
			overflowX: 'hidden',
			overflowY: 'auto',
			height: '100%',
		},
		container: {
			borderLeft: 'none',
			height: 'auto',
			width: 'auto',
			margin: 0,
		},
	},
	'@media (min-width: 784px)': {
		court: {
			background: 'black',
			display: 'flex',
			justifyContent: 'center',
		},
	},
}))

function Section({ title, content }) {
	return content ? <Stack spacing={false}>
		<Typography variant='overline'>{title}</Typography>
		<Typography variant='body1'>{content}</Typography>
	</Stack> : null
}

export default function DrillViewDialog2() {
	const classes = useStyles()
	const isMobile = useMediaQuery(isMobileMediaQuery)
	const courtRef = useRef()

	const [ featuredDrillsChanged, setFeaturedDrillsChanged ] = useState(false)
	const [ fetchedData, setFetchedData ] = useState(null)
	const { state, dispatch } = useStore()
	const { open, drill, drillId } = state.drillViewDialog

	// ensure the drill is always upgraded
	if (drill) drillHelpers.upgrade(drill)

	const hide = () => {
		if (featuredDrillsChanged) {
			state.setDrillsDirty(dispatch, true)
		}
		setFetchedData(null)
		dispatch({ type: 'drill_view_dialog.hide' })
	}

	const handleEdit = () => {
		hide()
		state.editDrill(dispatch, { drill })
	}

	useEffect(() => {
		if (!open || drill) return

		return utils.withCancel(async (cancelled) => {
			state.showLoading(dispatch)
			const res = await api.getDrill(drillId)
			state.hideLoading(dispatch)

			if (!res.ok) {
				hide()
				state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}

			if (cancelled()) return
			dispatch({ type: 'drill_view_dialog.loaded', drill: res.drill })
		})
		// eslint-disable-next-line
	}, [state.drillViewDialog])

	useEffect(() => {
		if (!drill) return
		return utils.withCancel(async (cancelled) => {
			const { limit_goal, limit_kind } = drill.data
			if (limit_kind === DrillLimitKind.none || limit_goal === DrillLimitGoal.none) {
				return
			}

			const res = await api.getDrillSessionBestLimitScores({ drill_id: drill.id, count: 1 })

			if (!res.ok) {
				state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}

			if (cancelled()) return

			setFetchedData(res)
		})
		// eslint-disable-next-line
	}, [state.drillViewDialog.drill])

	let content
	if (drill) {
		const { description } = drill
		const { mode, per_spot, player_count, seconds_between_throws, is_sequential, is_group_drill } = drill.data
		const { decisions, decision_mode_show_color, decision_mode_show_icon, decision_mode_show_text, is_decision_mode } = drill.data
		const { limit_count, limit_goal, limit_kind } = drill.data

		const drillOwnedByCurrentUser = catalogHelpers.entityOwnedByCurrentUser(state.user, drill)
		const hasLimitAndGoal = limit_kind !== DrillLimitKind.none && limit_goal !== DrillLimitGoal.none

		let personalBestText = 'LOADING...'
		if (fetchedData) {
			const { scores } = fetchedData
			if (scores.length === 0) {
				personalBestText = 'NO BEST YET'
			}
			else {
				let { score, started_at } = scores[0]

				if (limit_goal === DrillLimitGoal.time)
					score = utils.formatDurationCompact(score)

				const startedAt = new Date(started_at + 'z').toLocaleDateString()
				personalBestText = `${score} on ${startedAt}`
			}
		}
	
		const editButton =
			<Button variant='contained' onClick={handleEdit}>{drillOwnedByCurrentUser ? 'EDIT' : 'CUSTOMIZE'}</Button>

		content = <Stack medium>
			{ isMobile ? editButton : undefined }
			<Section title='DESCRIPTION' content={description} />
            <Section title='PER SPOT' content={window.courtLib.types.getLocationBallShotModeText(mode)} />
			<Section title='SHOTS PER SPOT' content={per_spot} />
			<Section title='SECONDS BETWEEN THROWS' content={seconds_between_throws} />
			<Stack small>
				<Section title='PLAYER COUNT' content={player_count} />
				<DrillPlayerSlots count={player_count} />
			</Stack>
			<Section title='SEQUENCE SHOOTING' content={is_sequential ? 'Yes' : 'No'} />
			<Section title='GROUP DRILL' content={is_group_drill ? 'Yes' : 'No'} />
            {is_decision_mode && <div>
                <Typography variant='overline'>Decisions</Typography>
                <DecisionMode
                    decisions={decisions}
                    showColor={decision_mode_show_color}
                    showIcon={decision_mode_show_icon}
                    showText={decision_mode_show_text}
                />
                <div style={{ marginBottom: 12 }}></div>
            </div>}
			{ limit_kind !== DrillLimitKind.none && <Section
				title={limit_goal === DrillLimitGoal.none ? 'CHALLENGE' : 'GOAL'}
				content={drillHelpers.getLimitDescription(limit_count, limit_goal, limit_kind, false)}
			/> }
			{ hasLimitAndGoal && <Section title='PERSONAL BEST' content={personalBestText} /> }
			{ !isMobile ? editButton : undefined }
			{ hasLimitAndGoal && <FeaturedDrillsList drill={drill} onChange={() => setFeaturedDrillsChanged(true)} /> }
			{ state.user && state.user.info.can_assign_drills && <AssignedDrillsList drill={drill} /> }
		</Stack>
	}

	if (!drill)
		return <></>

	return <StandardDialog title={drill.name} contain={false} open={open} isMobile={isMobile} onCancel={() => hide()}>
		{ content ? <div className={classes.root}>
			<Court passedInRef={courtRef} data={drill.data} interactive={true} mode='drill' responsive className={classes.court} />
			<Container className={classes.container}>{content}</Container>
		</div> : null }
	</StandardDialog>
}
